.chatbot-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1000;
}

.chatbot-popup {
  position: fixed;
  bottom: 80px;
  right: 20px;
  width: 400px;
  height: 700px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: white;
  z-index: 1000;
}

.chatbot-header {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  background-color: #f1f1f1;
  border-bottom: 1px solid #ccc;
}

.close-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.chatbot-iframe {
  width: 100%;
  height: 100%;
  border: none;
}
