.App {
  font-family: Arial, sans-serif;
}

.header {
  color: white;
  text-align: center;
}

.nav {
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.nav .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  height: 40px;
  margin-right: 10px;
}

.logo span {
  font-size: 1.5em;
  color: #4caf50;
  font-weight: bold;
}

.App-nav ul {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 20px;
}

.App-nav ul li a {
  color: #333333;
  text-decoration: none;
  font-weight: bold;
}

.find-provider {
  background-color: #8bc34a;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  font-weight: bold;
}

.search-icon img {
  height: 24px;
  cursor: pointer;
}

.section {
  padding: 40px 0;
}

.container {
  width: 80%;
  margin: 0 auto;
}

.footer {
  background-color: #003366;
  color: white;
  padding: 10px 0;
  text-align: center;
}
